var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ArchiveIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Tambah")])];
      },
      proxy: true
    }])
  }, [_c('b-card', [_c('b-form', {}, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kategori Paket",
      "label-for": "kategori"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optkatpaket,
      "placeholder": "- Pilih -"
    },
    model: {
      value: _vm.ujianDataId.category_paket_id,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "category_paket_id", $$v);
      },
      expression: "ujianDataId.category_paket_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Ujian",
      "label-for": "nama-paket"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nama Ujian",
      "name": "nama-paket"
    },
    model: {
      value: _vm.ujianDataId.name,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "name", $$v);
      },
      expression: "ujianDataId.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Mode Ujian",
      "label-for": "mode-paket"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'default',
        text: 'Default'
      }, {
        value: 'pauli',
        text: 'Pauli'
      }],
      "placeholder": "- Pilih Mode Ujian -"
    },
    model: {
      value: _vm.ujianDataId.mode,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "mode", $$v);
      },
      expression: "ujianDataId.mode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Ujian (singkat)",
      "label-for": "harga"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "shortname",
      "placeholder": "shortname"
    },
    model: {
      value: _vm.ujianDataId.shortname,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "shortname", $$v);
      },
      expression: "ujianDataId.shortname"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Acak Soal (tidak berlaku untuk pauli)",
      "label-for": "acak"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optquestion
    },
    model: {
      value: _vm.ujianDataId.shuffle_question,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "shuffle_question", $$v);
      },
      expression: "ujianDataId.shuffle_question"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tampilkan Hasil",
      "label-for": "show_result"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optresult
    },
    model: {
      value: _vm.ujianDataId.show_result,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "show_result", $$v);
      },
      expression: "ujianDataId.show_result"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Instruksi",
      "label-for": "instruksi"
    }
  }, [_c('quill-editor', {
    attrs: {
      "id": "Konten",
      "options": _vm.editor_options
    },
    model: {
      value: _vm.ujianDataId.instruction,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "instruction", $$v);
      },
      expression: "ujianDataId.instruction"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "8",
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Kalkulasi Skor Ujian",
      "label-for": "type_score_calculation"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.typeScoreOptions
    },
    model: {
      value: _vm.ujianDataId.type_score_calculation,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "type_score_calculation", $$v);
      },
      expression: "ujianDataId.type_score_calculation"
    }
  }), _c('small', {
    staticClass: "my-1"
  }, [_c('strong', [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v("Jika ingin membuat ujian Tes Kepribadian, pilih opsi "), _c('span', {
    staticClass: "text-warning"
  }, [_vm._v("\"Berdasarkan Poin Opsi Soal\"")])])])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CheckCircleIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Soal")])];
      },
      proxy: true
    }])
  }, [_c('soal-ujian', {
    attrs: {
      "ujianDataId": _vm.ujianDataId
    }
  })], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-overlay :show="loading">
    <section>
      <b-row
        ><b-col md="12">
          <!-- <b-card> -->
          <b-tabs>
            <b-tab active>
              <!-- title -->
              <template #title>
                <feather-icon icon="ArchiveIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">Tambah</span>
              </template>

              <b-card>
                <!-- form -->
                <b-form class="">
                  <b-row>
                    <b-col sm="12" md="4">
                      <b-form-group label="Kategori Paket" label-for="kategori">
                        <b-form-select
                          v-model="ujianDataId.category_paket_id"
                          :options="optkatpaket"
                          placeholder="- Pilih -"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="4">
                      <b-form-group label="Nama Ujian" label-for="nama-paket">
                        <b-form-input
                          v-model="ujianDataId.name"
                          placeholder="Nama Ujian"
                          name="nama-paket"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="4">
                      <b-form-group label="Mode Ujian" label-for="mode-paket">
                        <b-form-select
                          v-model="ujianDataId.mode"
                          :options="[
                            { value: 'default', text: 'Default' },
                            { value: 'pauli', text: 'Pauli' },
                          ]"
                          placeholder="- Pilih Mode Ujian -"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="4">
                      <b-form-group
                        label="Nama Ujian (singkat)"
                        label-for="harga"
                      >
                        <b-form-input
                          v-model="ujianDataId.shortname"
                          name="shortname"
                          placeholder="shortname"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="4">
                      <b-form-group
                        label="Acak Soal (tidak berlaku untuk pauli)"
                        label-for="acak"
                      >
                        <b-form-select
                          v-model="ujianDataId.shuffle_question"
                          :options="optquestion"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="4">
                      <b-form-group
                        label="Tampilkan Hasil"
                        label-for="show_result"
                      >
                        <b-form-select
                          v-model="ujianDataId.show_result"
                          :options="optresult"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Instruksi" label-for="instruksi">
                        <quill-editor
                          id="Konten"
                          v-model="ujianDataId.instruction"
                          :options="editor_options"
                        >
                        </quill-editor>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="8" lg="6">
                      <b-form-group
                        label="Jenis Kalkulasi Skor Ujian"
                        label-for="type_score_calculation"
                      >
                        <b-form-select
                          v-model="ujianDataId.type_score_calculation"
                          :options="typeScoreOptions"
                        />

                        <small class="my-1">
                          <strong
                            ><span class="text-danger">*</span>Jika ingin
                            membuat ujian Tes Kepribadian, pilih opsi
                            <span class="text-warning"
                              >"Berdasarkan Poin Opsi Soal"</span
                            ></strong
                          >
                        </small>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-tab>
            <!--/ general tab -->
            <!-- Keunggulan -->
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon icon="CheckCircleIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">Soal</span>
              </template>
              <soal-ujian :ujianDataId="ujianDataId" />
            </b-tab>

            <!-- social links -->
          </b-tabs>
          <!-- </b-card> -->
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BOverlay,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SoalUjian from "./soal/IndexTambah.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    quillEditor,
    SoalUjian,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BOverlay,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      typeScoreOptions: [
        { value: "question_weight_based", text: "Berdasarkan Bobot Soal" },
        { value: "option_point_based", text: "Berdasarkan Poin Opsi Soal" },
      ],
      Modal: false,
      optkatpaket: [],
      optquestion: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      optresult: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      paketFAQData: [],
      ujianDataId: {
        type_ujian: 0,
        category_paket_id: null,
        mapel_id: 0,
        name: null,
        shortname: null,
        soal_ids: null,
        type_score_calculation: "question_weight_based",
      },

      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      // optkatujian: [],
      // optmapel: [],
      // form: {
      //     id: null,
      //     name: null,
      //     price: null,
      //     paket_id: null,
      // },
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Pertanyaan", sortable: true },
        { key: "price", label: "Jawaban", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Pertanyaan" },
        { id: "price", value: "Jawaban" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Paket",
      search: null,
      idd: null,
    };
  },
  watch: {
    "ujianDataId.mode"(mode) {
      if (mode == "pauli") {
        this.optquestion = [{ value: "0", text: "Tidak" }];
        this.ujianDataId.shuffle_question = 0;
      } else {
        this.optquestion = [
          { value: "1", text: "Ya" },
          { value: "0", text: "Tidak" },
        ];
      }
    },
    "ujianDataId.category_paket_id"(categoryId) {
      if (categoryId) {
        this.setStateCategoryPaket(categoryId);
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    setStateCategoryPaket(categoryId) {
      const category = this.optkatpaket.find((item) => item.id == categoryId);
      if (category) {
        this.$store.commit("adminujian/SET_SELECTED_CATEGORY_PAKET", category);
      }
    },
    ModalUbah(item) {
      this.ujianDataId = item;
      // this.ujianDataId.type_ujian = item.category.id;
      // this.ujianDataId.mapel_id = item.mapel.id;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Ujian";
      this.getDataKategoriUjian();
      // this.getDataMapel();
      this.Modal = true;
      // this.ModalEdit = true;
    },
    doFormatRupiah() {
      this.ujianDataId.price = this.formatRupiah(this.ujianDataId.price);
      this.ujianDataId.max_quota = this.formatRupiah(
        this.ujianDataId.max_quota
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalUpload() {
      this.id = this.$route.params.id;
      this.activeAction = "upload";
      this.doFormatRupiah();
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.doFormatRupiah();
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("adminujian/createUpdateDelete", [data])
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal(message = null) {
      const notifMessage = message
        ? message
        : "Gagal menyimpan data, harap cek kembali data yang diisi termasuk pilih soal";
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: notifMessage,
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (
        this.ujianDataId.soal_ids == null ||
        this.ujianDataId.soal_ids == ""
      ) {
        this.pesanGagal("Harap pilih soal sebelum disimpan");
        return false;
      }
      let payload = {
        // id: this.ujianDataId.id,
        // type_ujian: this.ujianDataId.type_ujian,
        user_id: this.user.id,
        // mapel_id: this.ujianDataId.mapel_id,
        category_paket_id: this.ujianDataId.category_paket_id,
        name: this.ujianDataId.name,
        mode: this.ujianDataId.mode,
        shortname: this.ujianDataId.shortname,
        show_result: this.ujianDataId.show_result,
        shuffle_question: this.ujianDataId.shuffle_question,
        instruction: this.ujianDataId.instruction,
        soal_ids: this.ujianDataId.soal_ids,
        type_score_calculation: this.ujianDataId.type_score_calculation,
      };

      // if (this.id) {
      //   payload.id = this.id;
      // }

      this.loading = true;
      this.$store
        .dispatch("adminujian/save", [payload])
        .then(() => {
          this.loading = false;
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.Modal = false;
          // this.ModalEdit = false;
          this.displaySuccess({
            text: "Ujian berhasil di" + this.activeAction,
          });

          // check jika ada paket query dari url,
          // maka return ke detail paket
          if (paketId) {
            this.$router.push(
              `/master-admin/paket-tryout/detail/${paketId}?active_tab=subtest`
            );
          } else {
            this.$router.push("/master-admin/ujian-admin");
          }
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    async getDataById() {
      this.$store
        .dispatch("adminujian/indexId", this.$route.params.id)
        .then((response) => {
          let ujianDataId = response.data;
          this.ujianDataId = ujianDataId;
          this.doFormatRupiah();
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataKategoriUjian() {
      // this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("adminujian/indexKategoriUjian", payload)
        .then((response) => {
          // this.loading = false;
          let optkatujian = response.data.data;
          optkatujian.map((item) => {
            item.value = item.id;
            item.text = item.nama_ujian;
          });
          this.optkatujian = optkatujian;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },

    async getDataMapel() {
      // this.loading = true;
      const payload = {};
      this.$store
        .dispatch("mapel/index", payload)
        .then((response) => {
          // this.loading = false;
          let optmapel = response.data.data;
          optmapel.map((item) => {
            item.value = item.id;
            item.text = item.nama_mapel;
          });
          this.optmapel = optmapel;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getDataPaket() {
      const payload = {};
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let paket_id = response.data.data;
          paket_id.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optkatpaket = paket_id;
          const { cat: categoryId } = this.$route.query;
          if (categoryId) {
            this.ujianDataId.category_paket_id = categoryId;
          }
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
  },
  created() {
    this.$store.commit("adminujian/SET_SELECTED_CATEGORY_PAKET", null);
  },
  mounted() {
    this.getDataPaket();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
